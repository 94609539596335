.Article {
  text-align: center;
}

.upperCase {
  text-transform: uppercase;
}

.titleCase {
  text-transform: capitalize;
}