/* Stylesheet */

.Wrapper {
  display: flex;
  flex: 1;
  padding: 0;
  flex-direction: column;
}

.Aside li {
  margin: 1em 0;
}

@media only screen and (min-width: 900px) {

  /* .Wrapper {
    display: flex;
    flex-direction: row;
  } */

  .Aside {
    margin: 0 1.2em;
    padding: 0 1.2em;
  }
}