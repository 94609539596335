/* Stylesheet */

.pageHeader {
  font-size: 1.2em;
}

.navSection {
  margin-top: 1em;
}

.socialLinks {
  display: none;
  visibility: hidden;
}

@media only screen and (min-width: 900px) {
  .pageHeader {
    line-height: 2em;
  }

  .navSection {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }

  .socialLinks {
    display: block;
    visibility: visible;
    font-size: 1.5em;
  }
}