.juiceRoomImgContainer {
  position: relative;
  z-index: 699;
  margin: 0 auto;
}

.juiceRoomImgContainer img {
  position: relative;
  width: auto;
  height: 100%;
  max-width: 100%;
}

.scientistContainer {
  z-index: 1000;
  position: absolute;
  top: calc(100% - 30em);
  left: 8%;
  /* transform: translate(-69%, 100%); */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1em;
}

.scientist {
  width: 20em;
  height: 30em;
  background-color: black;
}
.speechBubble {
  z-index: 700;
  align-self: flex-end;
  margin-bottom: 6em;
  margin-left: -2em;
  padding: 1em;  
  background-color: white;
  border: 3px solid black;
  border-radius: 1em;
  text-align: left;
}

.speechBubble,
.speechBubble h2 {
  color: black;
}

.speechBubble button {
  flex-direction: row;
}

.speechBubbleWick {
  margin-left: -3em;
  width: 0;
  height: 0;
  border-width: 0 0 3em 2em;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.speechBubbleWick div {
  margin: 0.6em 0 0 -1.5em;
  padding-top: 0.7em;
  width: 0;
  height: 0;
  border-width: 0 0 2em 1.6em;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
