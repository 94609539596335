html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #838383;
  color: #444;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6,
nav,
ul,
ol,
p {
  margin: 0;
  padding: 0;
  text-align: center;
}

header,
footer,
h1,h2,h3,h4,h5,h6 {
  color: #222;
}

a,
a:link,
a:hover,
a:active,
a:focus,
a:visited {
  text-decoration: none;
  color: inherit;
}

a:active,
a:visited {
  color: #111;
}

a:hover,
a:active,
a:focus {
  color: #666;
  text-decoration: underline;
}

a li {
  text-decoration: inherit;
}

article h1,article h2,article h3,article h4,article h5,article h6,
article p {
  margin-bottom: 0.8em;
}