/* Stylesheet */

.pageFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 1em;
}

.socialLinks {
  font-size: 2em;
}

@media only screen and (min-width: 900px) {

  .pageFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 1em;
  }

  .copyright {
    flex: 1;
  }

  .socialLinks {
    display: none;
    visibility: hidden;
  }

}