/* Stylesheet */

.Button {
  font: inherit;
  border: 1px solid #4f005f;
  background: #4f005f;
  color: white;
  padding: 0.75rem 3.5rem;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
}

.Button:hover,
.Button:active {
  background: #741188;
  border-color: #741188;
}

.Button:focus {
  outline: none;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}