.logo,
.title {
  display: inline-block;
  pointer-events: none;
}

.logo {
  margin: 0 0.5em;
  height: 3em;
  vertical-align: text-bottom;
}