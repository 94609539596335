/* Stylesheet */

.Header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.8em;
}

@media only screen and (min-width: 900px) {
  .Header {
    padding: 0.3em 1em;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}