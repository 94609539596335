/* Stylesheet */

.PageNavigation {
  display: flex;
  justify-content: space-around;
}

.PageNavigationDesktop {
  display: none;
  visibility: hidden;
}

.menuButton {
  z-index: 9999;
  position:fixed;
  left: 0.3em;
  margin-top: 2.5em;
  width: 2em;
  height: 2em;
  line-height: 1.7em;
  font-size: 0.8em;
}

.menuButtonOpen {
  position: absolute;
  left: unset;
  right: 1em;
}

.navTitle {
  text-transform: uppercase;
}

.activeMenuItem {
  color: red;
}

@media only screen and (min-width: 900px) {

  .navTitle {
    display: none;
    visibility: hidden;
  }

  .PageNavigation {
    margin: 0 2em auto 0;
  }

  .PageNavigationDesktop {
    display: flex;
    visibility: visible;
  }

  .UnorderedList,
  .ListItem {
    display: inline-block;
    margin: 0 0.4em;
  }

  .menuButton {
    display: none;
    visibility: hidden;
  }
}