/* Stylesheet */

.MainContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.MainContent .pageDescription {
  margin: 2em auto;
  width: 80%;
}